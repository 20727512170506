import React from "react";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import { keyframes } from "@mui/system";

const letterAnimationTop = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const letterAnimationBottom = keyframes`
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

const colorChange = keyframes`
    0% {
        text-shadow: 2px 2px 8px rgba(52, 71, 103, 0.2), -2px -2px 8px rgba(44, 60, 88, 0.2);
    }
    100% {
        text-shadow: 2px 2px 8px rgba(52, 71, 103, 0.8), -2px -2px 8px rgba(44, 60, 88, 0.8);
    }
`;

const HeaderText = () => {
  const renderTextWithAnimation = (text, animation) => {
    return text.split("").map((char, index) => (
      <MKTypography
        key={index}
        variant="h1"
        sx={({ breakpoints }) => ({
          display: "inline-block",
          color: "#ffffff",
          animation: `${animation} 0.5s ease-out ${index * 0.15}s forwards, ${colorChange} 3s linear infinite`,
          opacity: 0,
          textShadow: "2px 2px 8px rgba(0, 0, 0, 0.3)",
          [breakpoints.down("md")]: {
            fontSize: "2.875rem",
            textAlign: "center",
          },
          whiteSpace: char === " " ? "pre" : "normal",
        })}
      >
        {char}
      </MKTypography>
    ));
  };

  return (
    <MKBox display="flex" flexDirection="column" alignItems="center" py={6}>
      <MKBox>
        {renderTextWithAnimation("Na Neklance -", letterAnimationTop)}
      </MKBox>
      <MKBox mt={3}>
        {renderTextWithAnimation("Nad Koulkou", letterAnimationBottom)}
      </MKBox>
    </MKBox>
  );
};

export default HeaderText;

import B101 from '../../../../assets/pudorysy/B101.pdf';
import A102 from '../../../../assets/pudorysy/A102.pdf';
import A103 from '../../../../assets/pudorysy/A103.pdf';
import A104 from '../../../../assets/pudorysy/A104.pdf';
import A106 from '../../../../assets/pudorysy/A106.pdf';
import A107 from '../../../../assets/pudorysy/A107.pdf';
import B109 from '../../../../assets/pudorysy/B109.pdf';
import A110 from '../../../../assets/pudorysy/A110.pdf';
import A111 from '../../../../assets/pudorysy/A111.pdf';
import A112 from '../../../../assets/pudorysy/A112.pdf';
import B113 from '../../../../assets/pudorysy/B113.pdf';
import A114 from '../../../../assets/pudorysy/A114.pdf';
import B115 from '../../../../assets/pudorysy/B115.pdf';
import C201 from '../../../../assets/pudorysy/C201.pdf';
import C203 from '../../../../assets/pudorysy/C203.pdf';
import C204 from '../../../../assets/pudorysy/C204.pdf';
import C205 from '../../../../assets/pudorysy/C205.pdf';
import C206 from '../../../../assets/pudorysy/C206.pdf';
import C207 from '../../../../assets/pudorysy/C207.pdf';
import C208 from '../../../../assets/pudorysy/C208.pdf';
import C209 from '../../../../assets/pudorysy/C209.pdf';

export const columns = [
  { name: "Číslo jednotky", align: "center", value: "propertyNumber"  },
  { name: "Typ jednotky", align: "center", value: "propertyType" },
  { name: "Patro", align: "center", value: "floor" },
  { name: "Metráž", align: "center", value: "area" },
  { name: "Terasa", align: "center", value: "terrace" },
  { name: "č. sklepa", align: "center", value: "cellarNumber" },
  { name: "m² sklepa", align: "center", value: "cellarArea" },
  { name: "Původní cena", align: "center", value: "originalPrice" },
  { name: "Nová cena", align: "center", value: "newPrice" },
  { name: "půdorysy", align: "center", value: "floorPlans" }
];

export const rows = [
  {
    propertyNumber: "B 101",
    propertyType: "nebyt",
    floor: "1",
    area: "38,4",
    terrace: "",
    cellarNumber: "",
    cellarArea: "",
    originalPrice: "6 040 320",
    newPrice: "5 859 110",
    floorPlans: B101
  },
  {
    propertyNumber: "A 102",
    propertyType: "2+KK",
    floor: "2",
    area: "66,1",
    terrace: "",
    cellarNumber: "B 201",
    cellarArea: "2,8",
    originalPrice: "12 221 890",
    newPrice: "10 579 305",
    floorPlans: A102
  },
  {
    propertyNumber: "A 103",
    propertyType: "3+KK",
    floor: "2",
    area: "96,6",
    terrace: "",
    cellarNumber: "B 202",
    cellarArea: "4,4",
    originalPrice: "17 378 340",
    newPrice: "14 992 320",
    floorPlans: A103
  },
  {
    propertyNumber: "A 104",
    propertyType: "2+KK",
    floor: "2",
    area: "61,2",
    terrace: "",
    cellarNumber: "B 203",
    cellarArea: "3,3",
    originalPrice: "11 315 880",
    newPrice: "9 795 060",
    floorPlans: A104
  },
  {
    propertyNumber: "B 105",
    propertyType: "3+KK",
    floor: "2",
    area: "115,0",
    terrace: "",
    cellarNumber: "B 204",
    cellarArea: "3,3",
    originalPrice: "20 688 500",
    newPrice: "17 848 000",
    floorPlans: ""
  },
  {
    propertyNumber: "A 106",
    propertyType: "2+KK",
    floor: "3",
    area: "66,5",
    terrace: "",
    cellarNumber: "B 301",
    cellarArea: "3,1",
    originalPrice: "12 295 850",
    newPrice: "10 643 325",
    floorPlans: A106
  },
  {
    propertyNumber: "A 107",
    propertyType: "3+KK",
    floor: "3",
    area: "95,6",
    terrace: "",
    cellarNumber: "B 302",
    cellarArea: "3,4",
    originalPrice: "17 198 440",
    newPrice: "14 837 120",
    floorPlans: A107
  },
  {
    propertyNumber: "B 109",
    propertyType: "3+KK",
    floor: "3",
    area: "115,2",
    terrace: "",
    cellarNumber: "B 304",
    cellarArea: "3,2",
    originalPrice: "20 724 480",
    newPrice: "17 879 040",
    floorPlans: B109
  },
  {
    propertyNumber: "A 110",
    propertyType: "2+KK",
    floor: "4",
    area: "66,3",
    terrace: "",
    cellarNumber: "B 401",
    cellarArea: "2,8",
    originalPrice: "12 258 870",
    newPrice: "10 611 315",
    floorPlans: A110
  },
  {
    propertyNumber: "A 111",
    propertyType: "3+KK",
    floor: "4",
    area: "83,0",
    terrace: "13,5",
    cellarNumber: "B 402",
    cellarArea: "2,8",
    originalPrice: "16 146 025",
    newPrice: "13 614 920",
    floorPlans: A111
  },
  {
    propertyNumber: "A 112",
    propertyType: "2+KK",
    floor: "4",
    area: "60,7",
    terrace: "",
    cellarNumber: "B 403",
    cellarArea: "2,8",
    originalPrice: "11 223 430",
    newPrice: "9 715 035",
    floorPlans: A112
  },
  {
    propertyNumber: "B 113",
    propertyType: "3+KK",
    floor: "4",
    area: "118,9",
    terrace: "9,0",
    cellarNumber: "B 404",
    cellarArea: "6,7",
    originalPrice: "22 208 655",
    newPrice: "18 947 592",
    floorPlans: B113
  },
  {
    propertyNumber: "A 114",
    propertyType: "3+1",
    floor: "5",
    area: "109,4",
    terrace: "110,8",
    cellarNumber: "B 501",
    cellarArea: "3,4",
    originalPrice: "29 647 520",
    newPrice: "22 324 395",
    floorPlans: A114
  },
  {
    propertyNumber: "B 115",
    propertyType: "3+KK",
    floor: "5",
    area: "109,6",
    terrace: "16,7",
    cellarNumber: "B 502",
    cellarArea: "2,8",
    originalPrice: "21 219 205",
    newPrice: "17 917 064",
    floorPlans: B115
  },
  {
    propertyNumber: "C 201",
    propertyType: "3+KK",
    floor: "1",
    area: "79,4",
    terrace: "62,1",
    cellarNumber: "B 101",
    cellarArea: "4,8",
    originalPrice: "19 869 955",
    newPrice: "15 021 498",
    floorPlans: C201
  },
  // {
  //   propertyNumber: "202",
  //   propertyType: "sklep",
  //   floor: "1",
  //   area: "2,9",
  //   terrace: "",
  //   cellarNumber: "",
  //   cellarArea: "",
  //   originalPrice: "350 000",
  //   newPrice: "350 000",
  //   floorPlans: ""
  // },
  {
    propertyNumber: "C 203",
    propertyType: "2+KK",
    floor: "2",
    area: "61,7",
    terrace: "",
    cellarNumber: "B 201",
    cellarArea: "4,8",
    originalPrice: "11 408 330",
    newPrice: "9 875 085",
    floorPlans: C203
  },
  {
    propertyNumber: "C 204",
    propertyType: "2+KK",
    floor: "2",
    area: "60,6",
    terrace: "",
    cellarNumber: "B 202",
    cellarArea: "2,9",
    originalPrice: "11 204 940",
    newPrice: "9 699 030",
    floorPlans: C204
  },
  {
    propertyNumber: "C 205",
    propertyType: "2+KK",
    floor: "3",
    area: "61,6",
    terrace: "",
    cellarNumber: "B 301",
    cellarArea: "4,8",
    originalPrice: "11 389 840",
    newPrice: "9 859 080",
    floorPlans: C205
  },
  {
    propertyNumber: "C 206",
    propertyType: "2+KK",
    floor: "3",
    area: "60,7",
    terrace: "",
    cellarNumber: "B 302",
    cellarArea: "3,0",
    originalPrice: "11 223 430",
    newPrice: "9 715 035",
    floorPlans: C206
  },
  {
    propertyNumber: "C 207",
    propertyType: "2+KK",
    floor: "4",
    area: "61,5",
    terrace: "",
    cellarNumber: "B 401",
    cellarArea: "4,8",
    originalPrice: "11 371 350",
    newPrice: "9 843 075",
    floorPlans: C207
  },
  {
    propertyNumber: "C 208",
    propertyType: "2+KK",
    floor: "4",
    area: "60,5",
    terrace: "",
    cellarNumber: "B 402",
    cellarArea: "2,9",
    originalPrice: "11 186 450",
    newPrice: "9 683 025",
    floorPlans: C208
  },
  {
    propertyNumber: "C 209",
    propertyType: "3+KK",
    floor: "5",
    area: "106,8",
    terrace: "15,2",
    cellarNumber: "B 501",
    cellarArea: "5,1",
    originalPrice: "20 580 560",
    newPrice: "17 401 024",
    floorPlans: C209
  },
];
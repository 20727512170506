import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import SimpleInfoCard from "../../../examples/Cards/InfoCards/SimpleInfoCard";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import GradeIcon from '@mui/icons-material/Grade';
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import useVisibility from "../../../utils/useVisibility";

const features = [
    { icon: <LocationOnIcon />, title: "Ideální lokalita", description: "Blízko centra a s výbornou dostupností." },
    { icon: <MoveToInboxIcon />, title: "Okamžité nastěhování", description: "Byty jsou připravené k nastěhování bez čekání." },
    { icon: <GradeIcon />, title: "Špičková kvalita", description: "Pouze nejlepší materiály a moderní design." },
];

function Features() {
    const [isVisible, sectionRef] = useVisibility();

    return (
      <MKBox
        component="section"
        py={4}
        sx={{background: "linear-gradient(to right, #f8f9fa, #e9ecef)" }}
        mt={-2}
        mx={-2}
        ref={sectionRef}
      >
          <Container>
              <Grid
                container
                item
                xs={12}
                lg={6}
                justifyContent="center"
                mx="auto"
                textAlign="center"
                pb={6}
              >
                  <MKTypography variant="h2" mb={1} sx={{fontSize: "2rem"}}>
                      Klíčové vlastnosti projektu
                  </MKTypography>
                  {/*<MKTypography variant="body1" color="text">*/}
                  {/*    Objevte výhody našeho rezidenčního projektu, který nabízí komfort a kvalitu v jedinečné lokalitě.*/}
                  {/*</MKTypography>*/}
              </Grid>
              <Grid container spacing={3}>
                  {features.map((item, index) => (
                    <Grid
                      item
                      xs={12}
                      md={4}
                      key={index}
                      sx={{
                          opacity: isVisible ? 1 : 0,
                          transform: isVisible ? "translateY(0)" : "translateY(50px)",
                          transition: `opacity 0.6s ease-out ${index * 0.1}s, transform 0.6s ease-out ${index * 0.1}s`,
                      }}
                    >
                        <SimpleInfoCard
                          icon={item.icon}
                          title={item.title}
                          description={item.description}
                          direction="center"
                          bgColor="dark"
                        />
                    </Grid>
                  ))}
              </Grid>
          </Container>
      </MKBox>
    );
}

export default Features;

import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import L from 'leaflet';
import MKBox from "../../../components/MKBox";

const redIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const blueIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const Maps = () => {
  const position = [50.061021, 14.399350];

  const locations = [
    {
      name: "Sportcentrum Radlice",
      lat: 50.0582099,
      lng: 14.3878892,
      description: "Sportovní centrum a bazén",
      icon: blueIcon,
      mapyCzLink: "https://mapy.cz/zakladni?x=14.3878892&y=50.0582099&z=17&source=firm&id=4019909"
    },
    {
      name: "Škola pro sluchově postižené",
      lat: 50.0597786,
      lng: 14.3929615,
      description: "Základní škola pro sluchově postižené děti",
      icon: blueIcon,
      mapyCzLink: "https://mapy.cz/zakladni?x=14.3929615&y=50.0597786&z=17&source=coor&id=50.0597786%2C14.3929615"
    },
    {
      name: "Dětské hřiště Na Brabenci",
      lat: 50.0587964,
      lng: 14.393321,
      description: "Park a dětské hřiště Na Brabenci",
      icon: blueIcon,
      mapyCzLink: "https://mapy.cz/zakladni?x=14.393321&y=50.0587964&z=17&source=coor&id=50.0587964%2C14.393321"
    },
    {
      name: "Restaurace U Prince Miroslava",
      lat: 50.061472,
      lng: 14.401111,
      description: "Tradiční česká restaurace s výbornou kuchyní",
      icon: blueIcon,
      mapyCzLink: "https://mapy.cz/zakladni?x=14.401111&y=50.061472&z=17&source=firm&id=392933"
    },
    {
      name: "Autobusová zastávka Radlická",
      lat: 50.0615907,
      lng: 14.40194,
      description: "Autobusová zastávka Radlická",
      icon: blueIcon,
      mapyCzLink: "https://mapy.cz/zakladni?x=14.40194&y=50.0615907&z=17&source=coor&id=50.0615907%2C14.40194"
    },
    {
      name: "Na Neklance - Nad Koulkou",
      lat: 50.060703,
      lng: 14.401846,
      description: "Nový bytový projekt",
      icon: redIcon,
      mapyCzLink: "https://mapy.cz/zakladni?x=14.401846&y=50.060703&z=17&source=coor&id=50.060703%2C14.401846"
    },
    {
      name: "Fitnes Star Radlice",
      lat: 50.057713,
      lng: 14.393679,
      description: "Fitness centrum s moderním vybavením",
      icon: blueIcon,
      mapyCzLink: "https://en.mapy.cz/zakladni?source=firm&id=13068589&x=14.3932728&y=50.0589609&z=17"
    }
  ];

  return (
    <MKBox component="section" py={4} sx={{ backgroundColor: '#fff' }}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={12}>
            <MKBox
              sx={{
                border: "1px solid #ddd",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <MapContainer center={position} zoom={16} maxZoom={22} style={{ width: '100%', height: '60vh' }} scrollWheelZoom={false}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  maxZoom={22}
                />
                {locations.map((loc, idx) => (
                  <Marker
                    key={idx}
                    position={[loc.lat, loc.lng]}
                    icon={loc.icon}
                  >
                    <Popup>
                      <b>{loc.name}</b><br />{loc.description}
                      <br />
                      <a
                        href={loc.mapyCzLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: '#2c3c58',
                          textDecoration: 'underline',
                          marginTop: '10px',
                          display: 'inline-block'
                        }}
                      >
                        Zobrazit na mapy.cz
                      </a>
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Maps;

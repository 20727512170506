import PropTypes from "prop-types";
import CountUp from "react-countup";
import MKTypography from "../../../../components/MKTypography";
import MKBox from "../../../../components/MKBox";

function DefaultCounterCard({ color, count, title, description, ...rest }) {
  return (
    <MKBox
      p={2}
      textAlign="center"
      lineHeight={1}
      sx={{
        paddingLeft: { xs: 0, sm: 2 },
      }}
    >
      <MKTypography variant="h1" color={color} textGradient>
        <CountUp end={count} duration={1} {...rest} />
      </MKTypography>
      {title && (
        <MKTypography variant="h5" mt={2} mb={1}>
          {title}
        </MKTypography>
      )}
      {description && (
        <MKTypography variant="body2" color="text" sx={{textWrap: "pretty"}}>
          {description}
        </MKTypography>
      )}
    </MKBox>
  );
}

DefaultCounterCard.defaultProps = {
  color: "info",
  description: "",
  title: "",
};

DefaultCounterCard.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DefaultCounterCard;

import React, { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import DefaultCounterCard from "../../../examples/Cards/CounterCards/DefaultCounterCard";
import MKBox from "../../../components/MKBox";

const Stats = () => {
  const minArea = 34;
  const minPrice = 6040320;

  const [isVisible, setIsVisible] = useState(false);
  const statsRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (statsRef.current) {
      observer.observe(statsRef.current);
    }

    return () => {
      if (statsRef.current) {
        observer.unobserve(statsRef.current);
      }
    };
  }, []);

  return (
    <MKBox
      component="section"
      py={3}
      mx={-2}
      sx={{
        background: "linear-gradient(to right, #f8f9fa, #e9ecef)",
      }}
      ref={statsRef}
    >
      <Container sx={{ maxWidth: "100%", px: 0 }}>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto", px: 0 }} spacing={0}>
          {isVisible && (
            <>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: { xs: 0, sm: 2 },
                }}
              >
                <DefaultCounterCard
                  count={22}
                  title="Jednotek"
                  description="Počet jednotek v projektu"
                  color="#747b8a"
                  duration={4}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: { xs: 0, sm: 2 },
                }}
              >
                <DefaultCounterCard
                  count={minArea}
                  title="Plocha od"
                  description={`Minimální plocha jednotky (${minArea} m²)`}
                  color="#747b8a"
                  duration={4}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: { xs: 0, sm: 2 },
                }}
              >
                <DefaultCounterCard
                  count={minPrice}
                  title="Cena od"
                  description={`Minimální cena jednotky (${minPrice.toLocaleString()} Kč)`}
                  color="#747b8a"
                  duration={4}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </MKBox>
  );
};

export default Stats;

import React, { useRef, useState } from "react";
import MKBox from "../../../components/MKBox";
import IconButton from "@mui/material/IconButton";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import video from "../../../assets/videos/preview.mp4";

function Header() {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMuteUnmute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };

  return (
    <MKBox
      minHeight="72vh"
      width="100%"
      position="relative"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        overflow: "hidden",
        backgroundPosition: "center center",
        color: "white",
        opacity: 1,
        boxShadow: "none",
        placeItems: "center",
      }}
    >
        <MKBox
          component="video"
          ref={videoRef}
          src={video}
          autoPlay
          loop
          muted={isMuted}
          playsInline
          loading="lazy"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            zIndex: -1,
          }}
        />
        <MKBox
          sx={{
            position: "absolute",
            top: 20,
            left: 16,
            display: "flex",
            gap: 2,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            borderRadius: "8px",
            padding: "8px",
          }}
        >
          <IconButton
            onClick={handlePlayPause}
            sx={{ color: "white" }}
          >
            {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </IconButton>
          <IconButton
            onClick={handleMuteUnmute}
            sx={{ color: "white" }}
          >
            {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
          </IconButton>
        </MKBox>
    </MKBox>
  );
}

export default Header;

import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
import { Modal, useMediaQuery } from "@mui/material";
import bg1 from "../../../assets/images/latest/selected/1-1.jpg";
import bg2 from "../../../assets/images/latest/selected/1-4.jpg";
import bg3 from "../../../assets/images/latest/selected/1-6.jpg";
import bg5 from "../../../assets/images/latest/selected/1-7.jpg";
import bg6 from "../../../assets/images/latest/selected/1-9.jpg";
import bg7 from "../../../assets/images/latest/selected/1-10.jpg";
import bg8 from "../../../assets/images/latest/selected/1-11.jpg";
import bg10 from "../../../assets/images/latest/selected/1-18.jpg";
import bg11 from "../../../assets/images/latest/selected/1-22.jpg";
import bg12 from "../../../assets/images/latest/selected/1-25.jpg";
import bg13 from "../../../assets/images/latest/selected/1-26.jpg";
import bg14 from "../../../assets/images/latest/selected/1-29.jpg";
import bg15 from "../../../assets/images/latest/selected/1-31.jpg";
import bg16 from "../../../assets/images/latest/selected/1-36.jpg";

import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import SwiperCore, { Navigation, Pagination, EffectCoverflow } from "swiper";

SwiperCore.use([Navigation, Pagination, EffectCoverflow]);

const images = [bg13, bg1, bg2, bg3, bg5, bg6, bg7, bg8, bg10,bg11,bg12, bg14, bg15, bg16];

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const isMobile = useMediaQuery('(max-width:600px)');

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const uniqueClassName = 'swiper-gallery';

  return (
    <MKBox component="section" py={6} className={uniqueClassName}>
      <style>
        {`
          .${uniqueClassName} .container {
            max-width: 124rem;
            padding: 4rem 1rem;
            margin: 0 auto;
          }

          .${uniqueClassName} .heading {
            padding: 1rem 0;
            font-size: 2rem;
            text-align: center;
            color: #344767;
          }

          .${uniqueClassName} .swiper_container {
            height: ${isMobile ? 'auto' : '52rem'};
            padding: ${isMobile ? '0' : '2rem 0'};
            position: relative;
          }

          .${uniqueClassName} .swiper-slide {
            width: ${isMobile ? '90%' : '37rem'};
            height: ${isMobile ? 'auto' : '42rem'};
            position: relative;
            box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
            transition: transform 0.4s, filter 0.4s, box-shadow 0.4s;
            margin: ${isMobile ? '0 auto 1rem auto' : '0 auto'};
            background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(240, 240, 240, 0.9));
            overflow: hidden;
          }
          
          .${uniqueClassName} .swiper-slide:not(.swiper-slide-active) {
            filter: grayscale(1) brightness(0.6);
            box-shadow: none;
            transform: scale(0.92) rotate(2deg);
          }

          .${uniqueClassName} .swiper-slide:hover {
            transform: scale(1.05);
            filter: none;
          }

          .${uniqueClassName} .swiper-slide img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          .${uniqueClassName} .swiper-slide-shadow-left,
          .${uniqueClassName} .swiper-slide-shadow-right {
            display: none;
          }

          .${uniqueClassName} .slider-controler {
            position: absolute;
            top: 50%;
            width: 100%;
            display: flex;
            justify-content: space-between;
            transform: translateY(-50%);
            padding: 0 2rem;
            box-sizing: border-box;
          }

          .${uniqueClassName} .slider-controler .swiper-button-next,
          .${uniqueClassName} .slider-controler .swiper-button-prev {
            color: #222224;
            width: 3.5rem;
            height: 3.5rem;
            border-radius: 50%;
            background: var(--white);
            filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .${uniqueClassName} .slider-controler .swiper-button-next::after,
          .${uniqueClassName} .slider-controler .swiper-button-prev::after {
            font-size: 2rem;
          }

          .${uniqueClassName} .swiper-pagination {
            position: relative;
            bottom: 1rem;
            width: 100%;
            text-align: center;
            margin: 0;
          }

          .${uniqueClassName} .swiper-pagination-bullet {
            background: #344767;
            opacity: 1;
            width: 1rem;
            height: 1rem;
            margin: 0 0.3rem;
          }

          .${uniqueClassName} .swiper-pagination-bullet-active {
            background: white;
            border: 1px solid #344767;
          }
        `}
      </style>
      <Container>
        <Grid
          container
          item
          xs={12}
          md={8}
          flexDirection="column"
          alignItems="center"
          mx="auto"
          textAlign="center"
        >
          <MKTypography variant="h3" className="heading">
            Prozkoumejte svůj budoucí domov
          </MKTypography>
        </Grid>
        <MKBox className="swiper_container">
          <Swiper
            effect={isMobile ? 'slide' : 'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            slidesPerView={isMobile ? 1 : 'auto'}
            spaceBetween={isMobile ? 10 : 30}
            coverflowEffect={isMobile ? {} : {
              rotate: 0,
              stretch: 0,
              depth: 100,
              modifier: 2.5,
            }}
            pagination={{ el: `.${uniqueClassName} .swiper-pagination`, clickable: true }}
            navigation={{
              nextEl: `.${uniqueClassName} .swiper-button-next`,
              prevEl: `.${uniqueClassName} .swiper-button-prev`,
              clickable: true,
            }}
            className="swiper_container"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index} onClick={() => openModal(index)}>
                <img src={image} alt={`slide-${index}`} />
              </SwiperSlide>
            ))}
            <div className={`swiper-pagination ${uniqueClassName}`}></div>
          </Swiper>
        </MKBox>
      </Container>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <MKBox
          sx={{
            position: 'relative',
            width: '95%',
            maxHeight: '80%',
            backgroundColor: 'white',
            borderRadius: '20px',
            overflow: 'hidden',
            transition: 'all 0.3s ease-in-out',
          }}
        >
          <Swiper
            navigation={!isMobile}
            pagination={{ clickable: true }}
            initialSlide={currentIndex}
            onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <MKBox
                  component="img"
                  src={image}
                  alt={`slide-${index}`}
                  width="100%"
                  height="auto"
                  sx={{
                    maxHeight: "80vh",
                    objectFit: "cover",
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    },
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </MKBox>
      </Modal>
    </MKBox>
  );
};

export default Gallery;

import React, { useState } from "react";
import PropTypes from "prop-types";
import MuiTable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import MKBox from "../../../components/MKBox";
import FolderIcon from "@mui/icons-material/Folder";
import IconButton from "@mui/material/IconButton";
import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import pdfInfo from "../../../assets/pudorysy/additional_data.pdf";

function DataTable({ columns, rows }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderColumns = columns.map(({ name, align }, key) => (
    <MKBox
      key={key}
      component="th"
      textAlign={align}
      fontWeight="bold"
      py={1}
      px={2}
      sx={{
        borderBottom: "2px solid lightgrey",
        backgroundColor: "white",
        fontSize: "16px",
        color: "#344767",
        position: "sticky",
        top: 0,
        zIndex: 1,
      }}
    >
      {name.charAt(0).toUpperCase() + name.slice(1)}
    </MKBox>
  ));

  const renderRows = rows
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row, index) => (
      <TableRow
        key={index}
        sx={{
          backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white",
          "&:hover": {
            backgroundColor: "#E1E8EB",
          },
        }}
      >
        {columns.map((col) => {
          const isPriceColumn =
            col.name === "Původní cena" || col.name === "Nová cena";
          return (
            <MKBox
              key={col.name}
              component="td"
              textAlign={col.align}
              px={isMobile ? 1 : 2}
              sx={{
                borderBottom: "1px solid lightgrey",
                fontSize: isMobile && isPriceColumn ? "12px" : "14px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                minWidth: isMobile && isPriceColumn ? "120px" : "auto", // Adjust this width as needed
              }}
            >
              {col.name === "půdorysy" ? (
                row[col.value] ? (
                  <IconButton
                    component="a"
                    href={row[col.value]}
                    download
                    sx={{
                      color: "#344767",
                    }}
                  >
                    <FolderIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled
                    sx={{
                      color: "grey",
                    }}
                  >
                    <FolderIcon />
                  </IconButton>
                )
              ) : (
                row[col.value]
              )}
            </MKBox>
          );
        })}
      </TableRow>
    ));

  return (
    <>
      <TableContainer>
        <MuiTable>
          <thead>
          <TableRow>{renderColumns}</TableRow>
          </thead>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
      <Typography
        mt={3}
        p={isMobile ? 2 : 3}
        variant={isMobile ? "body2" : "h6"}
        sx={{ fontWeight: 500, textAlign: "center" }}
        color="#2c3c58"
      >
        Ke každému bytu lze dokoupit parkovací místo. Již od 560.000,-Kč včetně DPH
      </Typography>
      <Typography
        mt={2}
        p={isMobile ? 2 : 3}
        variant={isMobile ? "body2" : "h6"}
        sx={{
          fontWeight: 500,
          textAlign: "center",
          color: "#2c3c58",
          textDecoration: "underline",
          cursor: "pointer",
          "&:hover": {
            color: "#1A202C",
          },
        }}
      >
        <a
          href={pdfInfo}
          download="POPIS_STANDARDNÍHO_PROVEDENÍ_DOMU.pdf"
          style={{
            color: "inherit",
            textDecoration: "none",
            display: "block",
          }}
        >
          Popis standardního provedení domu a bytů ke stažení
        </a>
      </Typography>
    </>
  );
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DataTable;

import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import DataTable from "./DataTable";
import * as tableData from "./data/tableData";

function Table() {
  return (
    <MKBox pt={2} textAlign="center" sx={{ backgroundColor: "#F4F4F4", padding: "2rem 0" }} mx={-2}>
      <MKTypography
        variant="h2"
        color="primary"
        mb={4}
        textAlign="center"
        sx={{
          fontWeight: "bold",
          background: "linear-gradient(90deg, #344767 0%, #2D4059 100%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent"
        }}
      >
        Ceník
      </MKTypography>
      <MKBox px={2}>
        <DataTable columns={tableData.columns} rows={tableData.rows} />
      </MKBox>
    </MKBox>
  )
}

export default Table;

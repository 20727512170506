import MKBox from "../../../components/MKBox";
import bgImage from "../../../assets/images/latest/bg_1.jpg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import HeaderText from "../DefaultHeader/HeaderText";

function MobileHeader() {
  return (
    <MKBox
      minHeight="60vh"
      width="100%"
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.5),
            rgba(gradients.dark.state, 0.5)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: "grid",
        placeItems: "center",
      }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <HeaderText/>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default MobileHeader;

import React, { useEffect, useCallback } from "react";
import { useMediaQuery } from "@mui/material";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import DefaultNavbar from "../examples/Navbars/DefaultNavbar";
import MKBox from "../components/MKBox";
import MKTypography from "../components/MKTypography";
import theme from "../assets/theme";
import Contacts from "./sections/Contacts";
import Table from "./components/Table";
import Stats from "./sections/About/Stats";
import Gallery from "./sections/Gallery";
import Footer from "./sections/Footer";
import Header from "./sections/DefaultHeader";
import Maps from "./sections/Maps";
import MobileHeader from "./sections/MobileHeader";
import Features from "./components/Features";
import AboutText from "./sections/About/AboutText";
import Testimonials from "../Rental/sections/Testimonials";

const routes = [
  { name: "O projektu", route: "#header" },
  { name: "Galerie", route: "#gallery" },
  { name: "Ceník", route: "#pricing" },
  { name: "Lokalita", route: "#location" },
  { name: "Kontakt", route: "#contact" },
];

function Rental() {
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderHeader = useCallback(() => {
    return isMobile ? <MobileHeader /> : <Header />;
  }, [isMobile]);

  useEffect(() => {
    const correctScrollPosition = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const elementTop = element.getBoundingClientRect().top + window.pageYOffset;
          const scrollPosition = window.pageYOffset;
          const yOffset = -80; // Adjust this value based on your navbar height

          // Check if the current scroll position is off by a significant amount
          if (Math.abs(elementTop - scrollPosition) > 10) { // 10px threshold
            const correctedPosition = elementTop + yOffset;
            window.scrollTo({ top: correctedPosition, behavior: 'smooth' });
          }
        }
      }
    };

    // Listen for hash changes and initial load
    window.addEventListener('hashchange', correctScrollPosition);
    window.addEventListener('load', correctScrollPosition);

    return () => {
      window.removeEventListener('hashchange', correctScrollPosition);
      window.removeEventListener('load', correctScrollPosition);
    };
  }, []);

  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          route: "#contact",
          label: "+420 702 160 835",
          color: "secondary",
        }}
        center
        sticky
      />
      <div id="header">
        {renderHeader()}
      </div>
      <Card
        sx={{
          p: 2,
          mx: { xs: 0, sm: 2, lg: 3 },
          mt: -8, // Adjust this value based on your design needs
          mb: isMobile ? 0 : 4,
          pb: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          overflow: "hidden",
          ml: isMobile ? 0 : 2,
          mr: isMobile ? 0 : 2,
          borderRadius: isMobile ? 0 : '16px',
        }}
      >
        <MKBox>
          <AboutText />
          <Features />
        </MKBox>
        <MKBox id="gallery">
          <Gallery />
        </MKBox>
        <Stats />
        <MKBox id="pricing" sx={{ backgroundColor: "#F4F4F4" }} mx={-2}>
          <Container maxWidth="lg">
            <Table />
          </Container>
        </MKBox>
        <MKBox id="location">
          <MKBox sx={{ backgroundColor: "#fff" }} mx={-2}>
            <Container maxWidth="lg">
              <MKTypography
                variant="h2"
                color="primary"
                textAlign="center"
                pt={1}
                sx={{
                  fontWeight: "bold",
                  background: "linear-gradient(90deg, #344767 0%, #2D4059 100%)",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Lokalita
              </MKTypography>
              <Maps />
            </Container>
          </MKBox>
        </MKBox>
        <MKBox id="contact">
          <Contacts />
        </MKBox>
        <Testimonials />
      </Card>
      <MKBox width="100%" mt={isMobile ? 0 : 2}>
        <Footer />
      </MKBox>
    </>
  );
}

export default Rental;

import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

function AboutText() {
  return (
    <MKBox mt={-2} py={6}>
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} md={12}>
            <MKTypography
              variant="body1"
              color="#344767"
              opacity={0.8}
              textAlign="justify"
              sx={{
                fontSize: "1.25rem",
                lineHeight: 1.75,
                maxWidth: "800px",
                margin: "0 auto"
              }}
            >
              Rezidence Na Neklance / Nad Koulkou je tichou oázou v historickém srdci Prahy.
              Jen kousek od pulzujícího Smíchova, ale v úžasně klidné čtvrti.
              V blízkosti historických památek města, ale v moderním domě nové generace, který nabízí veškerý komfort a výhody současného bydlení.
              Pro vaši rodinu jsme připravili 24 bytů, které jsou ihned k nastěhování. Klid, bezpečnost a pohoda.
              Stojí za to je vidět. Stojí za to tady žít.
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default AboutText;

import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import bgPattern from "../../assets/images/shapes/pattern-lines.svg";
import team2 from "./someone.png";
import team3 from "./someone1.png";
import team4 from "./someone2.png";
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";
import SimpleReviewCard from "../../examples/Cards/ReviewCards/SimpleReviewCard";
import useVisibility from "../../utils/useVisibility";

function Testimonials() {
  const [isVisible, sectionRef] = useVisibility();

  return (
    <MKBox
      ref={sectionRef}
      position="relative"
      variant="gradient"
      bgColor="dark"
      py={2}
      mx={-2}
      sx={{
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(50px)",
        transition: "opacity 0.6s ease-out, transform 0.6s ease-out",
      }}
    >
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.2}
        display={{ xs: "none", md: "block" }}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKTypography variant="h2" color="white" mb={1}>
              Co říkají spokojení zákazníci o naší práci
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ mt: 10, mb: 6 }}>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            sx={{
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateY(0)" : "translateY(50px)",
              transition: "opacity 0.6s ease-out 0.2s, transform 0.6s ease-out 0.2s",
            }}
          >
            <SimpleReviewCard
              image={team2}
              name="Elena Korolkova"
              username="renata-khabekova"
              review="Dobrý den, chtěla bych touto cestou upřímně poděkovat společnosti RE/MAX a zejména paní Eleně Korolkové za neocenitelnou pomoc při prodeji našeho bytu v Rezidenci Nová Harfa na Praze 9 - Vysočany. Díky profesionálnímu přístupu a efektivitě byl byt prodán co nejrychleji za dobrou tržní cenu..."
              sx={{ height: "100%" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            sx={{
              mt: { xs: 12, md: 0 },
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateY(0)" : "translateY(50px)",
              transition: "opacity 0.6s ease-out 0.4s, transform 0.6s ease-out 0.4s",
            }}
          >
            <SimpleReviewCard
              image={team3}
              name="Ilja Čistov"
              username="Jiriotahal"
              review="Jsem spokojen se službami, které dodává makléř Ilya Čistov ze společnosti Lekvi…"
              sx={{ height: "100%" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            sx={{
              mt: { xs: 12, md: 0 },
              opacity: isVisible ? 1 : 0,
              transform: isVisible ? "translateY(0)" : "translateY(50px)",
              transition: "opacity 0.6s ease-out 0.6s, transform 0.6s ease-out 0.6s",
            }}
          >
            <SimpleReviewCard
              image={team4}
              name="Daria Baidala"
              username="Gleb Kapush"
              review="Daria je skvělá realitní makléřka, pomohla s výběrem a prohlídkou bytu, doprovázela mě ve všech fázích, poradila s bydlením v Praze a pomáhá s dotazy po transakci!..."
              sx={{ height: "100%" }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Testimonials;

import { useEffect } from "react";
import Rental from "./Rental";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./assets/theme";
import { BrowserRouter } from "react-router-dom";

function App() {
  useEffect(() => {
    if (window.ResizeObserver) {
      const resizeObserverErrorHandler = (e) => {
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          e.stopImmediatePropagation();
        }
      };
      window.addEventListener('error', resizeObserverErrorHandler);

      return () => {
        window.removeEventListener('error', resizeObserverErrorHandler);
      };
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Rental />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import breakpoints from "../../../assets/theme/base/breakpoints";
import MKTypography from "../../../components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import DefaultNavbarMobile from "./DefaultNavbarMobile";

function DefaultNavbar({ brand, routes, transparent, light, action, sticky, relative, center }) {
  const [dropdown, setDropdown] = useState(null);
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const toggleMobileNavbar = () => setMobileNavbar(!mobileNavbar);

  useEffect(() => {
    const updateView = () => {
      setMobileView(window.innerWidth < breakpoints.values.lg);
      setMobileNavbar(false);
    };

    window.addEventListener("resize", updateView);
    updateView();
    return () => window.removeEventListener("resize", updateView);
  }, []);

  const renderNavbarItems = () =>
    routes.map(({ name, route }) => (
      <MKTypography
        key={name}
        component="a"
        href={route}
        variant="button"
        textTransform="capitalize"
        py={1}
        px={3}
        fontSize="1.1rem"
        sx={{
          cursor: "pointer",
          transition: "all 300ms linear",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            color: "#2c3c58",
            borderRadius: "8px",
            padding: "10px",
          },
        }}
      >
        {name}
      </MKTypography>
    ));

  const renderDropdownMenu = () => (
    <Popper
      anchorEl={dropdown}
      open={Boolean(dropdown)}
      placement="top-start"
      transition
      style={{ zIndex: 10 }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
          <MKBox borderRadius="lg" p={2} mt={2} sx={{ backgroundColor: "white.main", boxShadow: 3 }}>
            {routes.map(({ name, collapse }) =>
              collapse && name === dropdown ? (
                collapse.map((item) => (
                  <MKTypography
                    key={item.name}
                    component="a"
                    href={item.route}
                    variant="button"
                    textTransform="capitalize"
                    py={0.625}
                    px={2}
                    sx={{ cursor: "pointer", transition: "all 300ms linear", "&:hover": { backgroundColor: "grey.200", color: "black" } }}
                  >
                    {item.name}
                  </MKTypography>
                ))
              ) : null
            )}
          </MKBox>
        </Grow>
      )}
    </Popper>
  );

  return (
    <Container sx={sticky ? { position: "fixed", top: 0, left: 0, right: 0, zIndex: 10, width: "100%" } : null}>
      <MKBox
        py={1}
        px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        my={relative ? 0 : 2}
        mx={relative ? 0 : 3}
        width={relative ? "100%" : "calc(100% - 48px)"}
        borderRadius="xl"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        position={relative ? "relative" : "absolute"}
        left={0}
        zIndex={3}
        sx={{
          backgroundColor: transparent ? "transparent" : "rgba(255, 255, 255, 0.9)",
          backdropFilter: transparent ? "none" : "saturate(200%) blur(30px)",
        }}
      >
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox component="a" href="#header" py={transparent ? 1.5 : 0.75}>
            <MKTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"}>
              {brand}
            </MKTypography>
          </MKBox>
          <MKBox display={{ xs: "none", lg: "flex" }} ml="auto" mr={center ? "auto" : 0}>
            {renderNavbarItems()}
          </MKBox>
          <MKBox ml={{ xs: "auto", lg: 0 }}>
            {(action && !mobileView) && (
              <MKButton
                component="a"
                href={action.route}
                variant={action.color === "white" || action.color === "default" ? "contained" : "gradient"}
                color={action.color || "info"}
                size="large"
                sx={{
                  fontSize: "1rem",
                  padding: "8px 16px",
                }}
              >
                {action.label}
              </MKButton>
            )}
          </MKBox>
          <MKBox display={{ xs: "inline-block", lg: "none" }} onClick={toggleMobileNavbar} sx={{ cursor: "pointer" }}>
            {mobileNavbar ? <CloseIcon /> : <MenuIcon />}
          </MKBox>
        </MKBox>
        <MKBox bgColor={transparent ? "white" : "transparent"} shadow={transparent ? "lg" : "none"} borderRadius="xl" px={transparent ? 2 : 0}>
          {mobileView && <DefaultNavbarMobile routes={routes} open={mobileNavbar} />}
        </MKBox>
      </MKBox>
      {dropdown && renderDropdownMenu()}
    </Container>
  );
}

DefaultNavbar.defaultProps = {
  brand: "Na Neklance - Nad Koulkou",
  transparent: false,
  light: false,
  action: false,
  sticky: true,
  relative: false,
  center: false,
};

DefaultNavbar.propTypes = {
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
        "default",
        "white",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default DefaultNavbar;
